/* DO NOT USE CSS TO STYLE USE STYLEDCOMPONENTS!!! ADD STYLE COMPONENTS DIRECTORY TO YOUR COMPONENT */

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

/* base styles */
:root {
  --main-color: #fbca00;
  height: 100%;
 
}
@font-face {
  font-family: "HelveticaNeueBold";   /*Can be any text*/
  src: local("HelveticaNeueLTStd-Bd"),
    url("assets/HelveticaNeueLTStd-Bd.woff") format("woff");
}
@font-face {
  font-family: "HelveticaNeueMedium";   /*Can be any text*/
  src: local("HelveticaNeueLTStd-Md"),
    url("assets/HelveticaNeueLTStd-Md.woff") format("woff");
}

* {
  font-family: "Poppins", sans-serif;
  
  
}
#root{
  height: 100%;
}
html {
  touch-action: manipulation;
  height: 100%;
  /* Prevent double tap zoom on mobile devices */
}

/* Set initial scale and maximum scale */
@viewport {
  zoom: 1.0;
  min-zoom: 1.0;
  max-zoom: 1.0;
  user-zoom: fixed;
}

body {
  background-color: #fff;
  height: 100%;
}
.modal {
  z-index: 99999 !important;
}
.modal-sm {
  max-width: 300px !important;
  margin: auto;
  margin-top: 300px !important;
}
.edit-vehicle-modal-header {
  padding: 1rem 1rem 0 1rem;
  font-weight: 600;
  margin-left: 3.2rem;
}
.edit-vehicle-modal-body {
  padding: 0 1rem 1rem 1rem;
}
@media (max-width: 420px) {
  .modal {
    padding-left: 0 !important;
  }
}
.filter-modal-header {
  font-weight: 600;
  border-bottom: 2px solid rgb(123, 123, 124, 0.25) !important;
  font-size: 1.4rem;
}
@media (max-width: 600px) {
  .filter-modal-header {
    font-size: 1rem;
  }
}
.modal-header-class {
  padding: 3rem;
  align-items: flex-start;
}
.modal-body-class {
  padding: 0 3rem 3rem 3rem;
}
@media (min-width: 560px) {
  .modal-header-class {
    font-size: 1.5rem;
  }
}
@media (max-width: 300px) {
  .modal-header-class {
    padding: 1rem;
  }
  .modal-body-class {
    padding: 0 1rem 1rem 1rem;
  }
}

.modal-dialog {
  margin-top: 150px;
}

.modal-header {
  border-bottom: 0 none;
  display: none;
}

.modal-footer {
  border-top: 0 none;
}
@media (max-width: 320px) {
  .modal-backdrop {
    width: 100%;
    height: 100%;
  }
}

/* Navbar */

.nav-main {
  display: var(--dflex);
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background-color: #fff;
}

.nav-outside {
  width: 100%;
  display: var(--dflex);
  justify-content: space-between;
  align-items: center;
}

.nav-logo {
  height: 42px;
  width: 42px;
  margin: 20px 0 0 80px;
}

.logo-img {
  left: 6.94%;
  right: 90.17%;
  top: 2.37%;
  bottom: 91.83%;
  margin-right: 0.3vw;
}

a {
  text-decoration: none;
  color: #72879f;
}

.nav-signup,
.nav-login {
  width: 115px;
  height: 40px;
  border-radius: 4px;
  background-color: var(--main-color);
  text-align: center;
}

.nav-login {
  background-color: #fff;
  border: 1px solid var(--main-color);
}

.nav-signup a {
  color: #fff;
  font-weight: 600;
  font-size: 0.875rem;
}

.nav-login a:focus,
.nav-login a:hover {
  color: var(--main-color);
}

.nav-signup a:focus,
.nav-signup a:hover {
  color: #fff;
}

/* user authentication */

.check-box {
  margin: 0.5rem;
}

.terms-of-use {
  color: var(--main-color);
}

.login-toggle {
  color: var(--main-color);
}

.page-not-found {
  position: relative;
  text-align: center;
}
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}


/* .nav-tabs .nav-link:hover {
  border-color: none !important;
  isolation: isolate;
} */
/* accordian */
button.accordion-button.collapsed {
  position: relative;
  background-color: #fcfcfc;
  /* display: flex;
  justify-content: space-between; */
}
.accordion-item {
  margin-bottom: 20px;
  background-color: #fcfcfc;
}
.number {
  border-radius: 50%;
  padding: 0px 5px;
  background-color: #ffd100;
  color: #fff;
  margin: 0px 10px;
  width: 30px;
  height: 30px;
  padding: 3px;
  text-align: center;
  display: inline-block;
}
.accordion-button.collapsed .number {
  background: #ebedf8;
  color: #72879f;
  display: inline-block;
}

button.accordion-button {
  font-size: 18px;
  color: #72879f !important;
}
/* .accordian-section p {
  font-size: 14px;
  color: #72879F;
} */
.accordion-button::after {
  display: none;
}
.accordion-button::before {
  display: none;
}
.accordion-button.number {
  background-color: #ffd100;
}
.accordion-label {
  font-size: 14px;
  line-height: 21px;
  color: #72879f;
}
.accordion-input {
  border: 1px solid #d5d5d5;
  border-radius: 6px;
}
.resetBtn {
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
  color: #fff;
}
.submitBtn {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
}
.email-section,
.vichle-detail-section,
.parking-section {
  margin-bottom: 25px;
  position: relative;
}
.email-section input[type="text"],
.vichle-detail-section input[type="text"] {
  width: 100%;
  padding: 7px 12px;
}
.btn-group button[type="submit"],
.vichle-detail-section button[type="submit"] {
  background: #ffd100;
  color: #fff;
  border: 0;
  padding: 7px;
  border-radius: 5px;
  width: 154px;
}
.btn-group button[type="reset"] {
  background: #b6b6b6;
  border-radius: 5px;
  border: 0;
  padding: 7px;
  width: 165px;
}
.addmore a {
  color: #ffd100;
  font-size: 14px;
  text-transform: capitalize;
}
.parking-section {
  display: flex;
}
.start input[type="text"],
.end input[type="text"] {
  width: 255px;
}
.form-check-input:checked {
  background-color: #1b810a;
  border-color: #1b810a;
}
.btn-group.purchage button[type="submit"] {
  width: 182px;
}
.payment-heading h4 {
  color: #626262;
  font-size: 24px;
}
.payment-section .nav-tabs .nav-link {
  color: #72879f;
}
.input-group-text img {
  margin: 0px 3px;
}
.input-group-text {
  border-left: 2px solid #fff;
  color: #212529;
  background-color: #ffffff;
}
.field-hidden {
  display: none;
}
input.form-control {
  outline: none !important;
  box-shadow: none !important;
  font-family: sans-serif;
}
.input-group input.form-control {
  border-right: 0px;
}
.paypal-img img {
  margin: 0px 12px;
}
.order-detail-accordion .accordion-button {
  background-color: transparent;
  box-shadow: none;
}

.order-detail-accordion .active {
  display: block;
}
.order-detail-accordion .remove {
  display: none;
}
.addNewVehicle {
  display: flex;
  justify-content: space-between;
}
.addPromoCode {
  font-size: 18px !important;
  line-height: 27px;
  color: #ffd100 !important;
}
.amt {
  font-weight: 600;
}
.navTabsBox .active {
  background-color: #ffd10021 !important;
  border-bottom: 5px solid #ffd100 !important;
}

/* Book now page design */
.BookNowContainer {
  overflow-y: auto;
}
.AccordionCard {
  margin-bottom: 20px;
  padding: 0 20px;
}

.Toastify__toast-container {
  z-index: 200000;
}

.no-data-text {
  text-align: center;
  padding: 0.5rem;
}

.wrap-ellipsisName {
  line-height: 1.1;
}
.wrap-ellipsisName {
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-vehicle-modal-header{
  font-size: 30px;
  font-weight: 600;
  padding-bottom: 0;
}
.MuiPopper-root {
  z-index: 100000 !important;
}
.card-update-header {
  font-weight: 500;
  font-size: 1.3rem;
}
.modal-sm {
  margin-top: 50px !important;
}
